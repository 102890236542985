export default {
  data: {
    gdp: { name: 'GDP per capita', format: '{0} USD', thousandSeparator: ',', thresholdMax: 5e4, thresholdMin: 1e3 },
    change: { name: 'Change to year before', format: '{0} %' },
    gdpAdjusted: {
      name: 'Purchasing Power Parity',
      format: '{0} USD',
      thousandSeparator: ',',
      thresholdMax: 5e4,
      thresholdMin: 1e3,
    },
    changeAdjusted: { name: 'Change to year before', format: '{0} %' },
  },
  applyData: 'gdpAdjusted',
  values: {
    AF: { gdp: 587, change: 4.73, gdpAdjusted: 1958, changeAdjusted: 0.02 },
    AL: {
      gdp: 4583,
      change: 11.09,
      gdpAdjusted: 12507,
      changeAdjusted: 4.04,
    },
    DZ: {
      gdp: 4293,
      change: 10.01,
      gdpAdjusted: 15237,
      changeAdjusted: 0.33,
    },
    AO: {
      gdp: 4408,
      change: 26.49,
      gdpAdjusted: 6753,
      changeAdjusted: -2.56,
    },
    AG: {
      gdp: 16702,
      change: 3.26,
      gdpAdjusted: 26231,
      changeAdjusted: 2.15,
    },
    AR: {
      gdp: 14467,
      change: 13.83,
      gdpAdjusted: 20876,
      changeAdjusted: 1.77,
    },
    AM: { gdp: 3861, change: 9.22, gdpAdjusted: 9456, changeAdjusted: 7.33 },
    AU: {
      gdp: 55707,
      change: 7.39,
      gdpAdjusted: 50334,
      changeAdjusted: 0.97,
    },
    AT: {
      gdp: 47290,
      change: 5.72,
      gdpAdjusted: 49869,
      changeAdjusted: 2.66,
    },
    AZ: {
      gdp: 4141,
      change: 6.29,
      gdpAdjusted: 17492,
      changeAdjusted: -0.91,
    },
    BS: {
      gdp: 31255,
      change: 2.21,
      gdpAdjusted: 31139,
      changeAdjusted: 0.31,
    },
    BH: {
      gdp: 24029,
      change: 6.32,
      gdpAdjusted: 48505,
      changeAdjusted: 1.02,
    },
    BD: { gdp: 1602, change: 9.79, gdpAdjusted: 4211, changeAdjusted: 6.1 },
    BB: {
      gdp: 17859,
      change: 7.83,
      gdpAdjusted: 18664,
      changeAdjusted: 0.64,
    },
    BY: {
      gdp: 5760,
      change: 14.69,
      gdpAdjusted: 18931,
      changeAdjusted: 2.52,
    },
    BE: { gdp: 43582, change: 5.3, gdpAdjusted: 46553, changeAdjusted: 1.13 },
    BZ: {
      gdp: 4806,
      change: -0.64,
      gdpAdjusted: 8324,
      changeAdjusted: -1.35,
    },
    BJ: { gdp: 830, change: 4.92, gdpAdjusted: 2277, changeAdjusted: 3.46 },
    BT: { gdp: 2903, change: 8.01, gdpAdjusted: 8744, changeAdjusted: 4.82 },
    BO: { gdp: 3353, change: 7.3, gdpAdjusted: 7547, changeAdjusted: 2.73 },
    BA: { gdp: 5149, change: 7.04, gdpAdjusted: 12724, changeAdjusted: 2.6 },
    BW: { gdp: 7877, change: 8.34, gdpAdjusted: 17828, changeAdjusted: 0.38 },
    BR: {
      gdp: 9895,
      change: 13.74,
      gdpAdjusted: 15602,
      changeAdjusted: 0.23,
    },
    BN: {
      gdp: 29712,
      change: 10.31,
      gdpAdjusted: 78196,
      changeAdjusted: -0.7,
    },
    BG: { gdp: 8064, change: 7.58, gdpAdjusted: 21687, changeAdjusted: 4.23 },
    BF: { gdp: 664, change: 8.11, gdpAdjusted: 1889, changeAdjusted: 3.55 },
    BI: { gdp: 312, change: 4.85, gdpAdjusted: 735, changeAdjusted: -5.2 },
    KH: { gdp: 1389, change: 8.76, gdpAdjusted: 4012, changeAdjusted: 5.46 },
    CM: { gdp: 1401, change: 2.94, gdpAdjusted: 3660, changeAdjusted: 0.62 },
    CA: { gdp: 45077, change: 6.27, gdpAdjusted: 48265, changeAdjusted: 2.1 },
    XK: { gdp: 3880, change: 6.23, gdpAdjusted: 10514, changeAdjusted: 3.14 },
    CV: { gdp: 3237, change: 4.91, gdpAdjusted: 6944, changeAdjusted: 2.69 },
    CF: { gdp: 387, change: 7.65, gdpAdjusted: 677, changeAdjusted: 2.45 },
    TD: { gdp: 810, change: -0.71, gdpAdjusted: 2344, changeAdjusted: -6.15 },
    CL: {
      gdp: 15070,
      change: 9.66,
      gdpAdjusted: 24537,
      changeAdjusted: 0.69,
    },
    CN: { gdp: 8643, change: 6.5, gdpAdjusted: 16660, changeAdjusted: 6.47 },
    CO: { gdp: 6273, change: 9.21, gdpAdjusted: 14485, changeAdjusted: 0.96 },
    KM: { gdp: 788, change: 3.09, gdpAdjusted: 1588, changeAdjusted: 0.25 },
    CG: { gdp: 1958, change: 6.66, gdpAdjusted: 6642, changeAdjusted: -7.2 },
    CR: {
      gdp: 11685,
      change: -0.77,
      gdpAdjusted: 16877,
      changeAdjusted: 2.24,
    },
    HR: { gdp: 13138, change: 6.7, gdpAdjusted: 24423, changeAdjusted: 3.15 },
    CY: {
      gdp: 24976,
      change: 5.65,
      gdpAdjusted: 37023,
      changeAdjusted: 3.95,
    },
    CZ: { gdp: 20152, change: 8.9, gdpAdjusted: 35512, changeAdjusted: 4.23 },
    CD: { gdp: 478, change: 2.32, gdpAdjusted: 790, changeAdjusted: 0.2 },
    DK: {
      gdp: 56444,
      change: 4.97,
      gdpAdjusted: 49883,
      changeAdjusted: 1.49,
    },
    DJ: { gdp: 1989, change: 4.52, gdpAdjusted: 3559, changeAdjusted: 5.19 },
    DM: {
      gdp: 7921,
      change: -3.63,
      gdpAdjusted: 11102,
      changeAdjusted: -4.69,
    },
    DO: { gdp: 7375, change: 3.67, gdpAdjusted: 16944, changeAdjusted: 3.5 },
    EC: { gdp: 6098, change: 2.21, gdpAdjusted: 11482, changeAdjusted: 1.29 },
    EG: {
      gdp: 2501,
      change: -32.17,
      gdpAdjusted: 12671,
      changeAdjusted: 2.36,
    },
    SV: { gdp: 4400, change: 4.06, gdpAdjusted: 8948, changeAdjusted: 1.88 },
    GQ: {
      gdp: 12727,
      change: 2.65,
      gdpAdjusted: 36017,
      changeAdjusted: -7.97,
    },
    ER: { gdp: 980, change: 13.88, gdpAdjusted: 1581, changeAdjusted: 2.73 },
    EE: {
      gdp: 19840,
      change: 11.47,
      gdpAdjusted: 31749,
      changeAdjusted: 5.08,
    },
    ET: { gdp: 873, change: 8.82, gdpAdjusted: 2161, changeAdjusted: 8.43 },
    FM: { gdp: 3200, change: 1.45, gdpAdjusted: 3393, changeAdjusted: 1.37 },
    FJ: { gdp: 5740, change: 7.44, gdpAdjusted: 9777, changeAdjusted: 3.08 },
    FI: {
      gdp: 46017,
      change: 5.75,
      gdpAdjusted: 44333,
      changeAdjusted: 2.63,
    },
    FR: {
      gdp: 39869,
      change: 4.35,
      gdpAdjusted: 43760,
      changeAdjusted: 1.46,
    },
    GA: {
      gdp: 7972,
      change: 6.96,
      gdpAdjusted: 19254,
      changeAdjusted: -1.33,
    },
    GM: { gdp: 480, change: 1.45, gdpAdjusted: 1713, changeAdjusted: 0.56 },
    GE: { gdp: 4099, change: 5.86, gdpAdjusted: 10747, changeAdjusted: 5.07 },
    DE: {
      gdp: 44550,
      change: 5.44,
      gdpAdjusted: 50425,
      changeAdjusted: 2.07,
    },
    GH: { gdp: 1663, change: 7.18, gdpAdjusted: 4729, changeAdjusted: 6.28 },
    GR: {
      gdp: 18637,
      change: 4.26,
      gdpAdjusted: 27737,
      changeAdjusted: 1.56,
    },
    GD: { gdp: 10360, change: 5.1, gdpAdjusted: 14926, changeAdjusted: 3.04 },
    GT: { gdp: 4472, change: 7.84, gdpAdjusted: 8145, changeAdjusted: 0.81 },
    GN: { gdp: 749, change: 11.88, gdpAdjusted: 2041, changeAdjusted: 4.09 },
    GW: { gdp: 794, change: 13.43, gdpAdjusted: 1845, changeAdjusted: 3.57 },
    GY: { gdp: 4710, change: 3.24, gdpAdjusted: 8161, changeAdjusted: 1.55 },
    HT: { gdp: 784, change: 3.96, gdpAdjusted: 1815, changeAdjusted: -0.03 },
    HN: { gdp: 2766, change: 4.66, gdpAdjusted: 5562, changeAdjusted: 3.17 },
    HK: { gdp: 46109, change: 6.01, gdpAdjusted: 61393, changeAdjusted: 2.9 },
    HU: {
      gdp: 15531,
      change: 3.16,
      gdpAdjusted: 29474,
      changeAdjusted: 4.32,
    },
    IS: {
      gdp: 70332,
      change: 16.46,
      gdpAdjusted: 51841,
      changeAdjusted: 2.87,
    },
    IN: { gdp: 1983, change: 13.35, gdpAdjusted: 7183, changeAdjusted: 5.43 },
    ID: { gdp: 3876, change: 7.53, gdpAdjusted: 12377, changeAdjusted: 4.33 },
    IR: { gdp: 5305, change: 5.53, gdpAdjusted: 20200, changeAdjusted: 3.24 },
    IQ: {
      gdp: 5088,
      change: 12.24,
      gdpAdjusted: 16954,
      changeAdjusted: -3.79,
    },
    IE: {
      gdp: 70638,
      change: 9.02,
      gdpAdjusted: 75539,
      changeAdjusted: 6.92,
    },
    IL: {
      gdp: 40258,
      change: 8.24,
      gdpAdjusted: 36340,
      changeAdjusted: 1.77,
    },
    IT: {
      gdp: 31984,
      change: 4.31,
      gdpAdjusted: 38140,
      changeAdjusted: 1.59,
    },
    CI: { gdp: 1617, change: 8.14, gdpAdjusted: 3883, changeAdjusted: 5.28 },
    JM: { gdp: 5048, change: 2, gdpAdjusted: 9163, changeAdjusted: 0.67 },
    JP: {
      gdp: 38440,
      change: -1.4,
      gdpAdjusted: 42832,
      changeAdjusted: 1.94,
    },
    JO: { gdp: 5678, change: 2.31, gdpAdjusted: 12494, changeAdjusted: 0.13 },
    KZ: {
      gdp: 8841,
      change: 18.57,
      gdpAdjusted: 26252,
      changeAdjusted: 2.87,
    },
    KE: { gdp: 1702, change: 9.66, gdpAdjusted: 3491, changeAdjusted: 2.29 },
    KI: { gdp: 1721, change: 6.42, gdpAdjusted: 1976, changeAdjusted: 0.43 },
    KW: {
      gdp: 26005,
      change: 5.6,
      gdpAdjusted: 66163,
      changeAdjusted: -4.31,
    },
    KG: { gdp: 1144, change: 7.19, gdpAdjusted: 3667, changeAdjusted: 3.05 },
    LA: { gdp: 2543, change: 5.2, gdpAdjusted: 7366, changeAdjusted: 5.38 },
    LV: {
      gdp: 15547,
      change: 10.98,
      gdpAdjusted: 27644,
      changeAdjusted: 5.58,
    },
    LB: {
      gdp: 11408,
      change: 2.73,
      gdpAdjusted: 19439,
      changeAdjusted: 0.63,
    },
    LS: { gdp: 1425, change: 12.94, gdpAdjusted: 3581, changeAdjusted: 1.78 },
    LR: { gdp: 729, change: -2.12, gdpAdjusted: 1354, changeAdjusted: -0.05 },
    LY: {
      gdp: 4859,
      change: 67.32,
      gdpAdjusted: 9987,
      changeAdjusted: 69.48,
    },
    LT: {
      gdp: 16730,
      change: 12.14,
      gdpAdjusted: 32299,
      changeAdjusted: 4.38,
    },
    LU: {
      gdp: 105803,
      change: 3.94,
      gdpAdjusted: 106374,
      changeAdjusted: 2.27,
    },
    MO: {
      gdp: 77451,
      change: 10.1,
      gdpAdjusted: 111629,
      changeAdjusted: 9.42,
    },
    MK: {
      gdp: 5474,
      change: 5.51,
      gdpAdjusted: 14914,
      changeAdjusted: -0.06,
    },
    MG: { gdp: 448, change: 11.5, gdpAdjusted: 1551, changeAdjusted: 1.37 },
    MW: { gdp: 324, change: 10.17, gdpAdjusted: 1167, changeAdjusted: 1.13 },
    MY: { gdp: 9813, change: 4.68, gdpAdjusted: 29041, changeAdjusted: 4.55 },
    MV: {
      gdp: 12527,
      change: 5.17,
      gdpAdjusted: 19151,
      changeAdjusted: 2.98,
    },
    ML: { gdp: 811, change: 5.62, gdpAdjusted: 2170, changeAdjusted: 2.31 },
    MT: {
      gdp: 27250,
      change: 8.83,
      gdpAdjusted: 41945,
      changeAdjusted: 6.26,
    },
    MH: { gdp: 3625, change: 8.21, gdpAdjusted: 3439, changeAdjusted: 1.83 },
    MR: { gdp: 1318, change: 5.15, gdpAdjusted: 4444, changeAdjusted: 0.52 },
    MU: { gdp: 9794, change: 1.88, gdpAdjusted: 21640, changeAdjusted: 3.67 },
    MX: { gdp: 9304, change: 5.64, gdpAdjusted: 19903, changeAdjusted: 0.91 },
    MD: { gdp: 2280, change: 19.51, gdpAdjusted: 5661, changeAdjusted: 4.24 },
    MN: {
      gdp: 3640,
      change: -0.75,
      gdpAdjusted: 12979,
      changeAdjusted: 3.65,
    },
    ME: { gdp: 7647, change: 8.8, gdpAdjusted: 17735, changeAdjusted: 4.16 },
    MA: { gdp: 3151, change: 4.89, gdpAdjusted: 8567, changeAdjusted: 2.96 },
    MZ: { gdp: 429, change: 9.5, gdpAdjusted: 1244, changeAdjusted: 0.09 },
    MM: { gdp: 1264, change: 4.41, gdpAdjusted: 6244, changeAdjusted: 5.82 },
    NA: {
      gdp: 5413,
      change: 14.96,
      gdpAdjusted: 11312,
      changeAdjusted: -3.12,
    },
    NR: { gdp: 8575, change: 9.6, gdpAdjusted: 12002, changeAdjusted: 1.22 },
    NP: { gdp: 834, change: 14.45, gdpAdjusted: 2679, changeAdjusted: 6.41 },
    NL: {
      gdp: 48346,
      change: 5.89,
      gdpAdjusted: 53635,
      changeAdjusted: 2.83,
    },
    NZ: {
      gdp: 41593,
      change: 6.51,
      gdpAdjusted: 38934,
      changeAdjusted: 2.03,
    },
    NI: { gdp: 2207, change: 2.57, gdpAdjusted: 5849, changeAdjusted: 3.82 },
    NE: { gdp: 440, change: 6.29, gdpAdjusted: 1164, changeAdjusted: 1.37 },
    NG: {
      gdp: 1994,
      change: -9.68,
      gdpAdjusted: 5929,
      changeAdjusted: -1.76,
    },
    NO: {
      gdp: 74941,
      change: 6.07,
      gdpAdjusted: 71831,
      changeAdjusted: 0.87,
    },
    OM: {
      gdp: 17973,
      change: 0.64,
      gdpAdjusted: 45157,
      changeAdjusted: -2.23,
    },
    PK: { gdp: 1541, change: 6.95, gdpAdjusted: 5358, changeAdjusted: 3.58 },
    PW: {
      gdp: 17096,
      change: 0.27,
      gdpAdjusted: 15934,
      changeAdjusted: -2.06,
    },
    PA: {
      gdp: 15089,
      change: 5.35,
      gdpAdjusted: 25351,
      changeAdjusted: 3.82,
    },
    PG: { gdp: 2861, change: 2.51, gdpAdjusted: 3674, changeAdjusted: 0.46 },
    PY: { gdp: 4260, change: 6.47, gdpAdjusted: 9826, changeAdjusted: 3.06 },
    PE: { gdp: 6199, change: 8.93, gdpAdjusted: 13334, changeAdjusted: 1.31 },
    PH: { gdp: 2976, change: 0.78, gdpAdjusted: 8315, changeAdjusted: 5.15 },
    PL: {
      gdp: 13822,
      change: 11.37,
      gdpAdjusted: 29521,
      changeAdjusted: 4.73,
    },
    PT: {
      gdp: 21161,
      change: 3.68,
      gdpAdjusted: 30417,
      changeAdjusted: 3.08,
    },
    PR: {
      gdp: 30488,
      change: -0.98,
      gdpAdjusted: 37339,
      changeAdjusted: -2.35,
    },
    QA: {
      gdp: 60804,
      change: 4.39,
      gdpAdjusted: 124529,
      changeAdjusted: -2.31,
    },
    RO: {
      gdp: 10757,
      change: 13.18,
      gdpAdjusted: 24508,
      changeAdjusted: 7.49,
    },
    RU: {
      gdp: 10608,
      change: 19.19,
      gdpAdjusted: 27834,
      changeAdjusted: 1.5,
    },
    RW: { gdp: 772, change: 5.2, gdpAdjusted: 2080, changeAdjusted: 3.7 },
    KN: { gdp: 16296, change: 1.9, gdpAdjusted: 26845, changeAdjusted: 1.71 },
    LC: { gdp: 9607, change: 1.99, gdpAdjusted: 14450, changeAdjusted: 2.51 },
    VC: { gdp: 7270, change: 3.99, gdpAdjusted: 11491, changeAdjusted: 0.7 },
    WS: { gdp: 4253, change: 5.93, gdpAdjusted: 5740, changeAdjusted: 1.8 },
    SM: { gdp: 47406, change: 3.8, gdpAdjusted: 59466, changeAdjusted: 1.65 },
    ST: { gdp: 1785, change: 5.7, gdpAdjusted: 3180, changeAdjusted: 2.58 },
    SA: {
      gdp: 21120,
      change: 3.95,
      gdpAdjusted: 54777,
      changeAdjusted: -2.63,
    },
    SN: { gdp: 1038, change: 8.73, gdpAdjusted: 2727, changeAdjusted: 4.39 },
    RS: { gdp: 5899, change: 8.71, gdpAdjusted: 15e3, changeAdjusted: 1.83 },
    SC: {
      gdp: 15686,
      change: 2.97,
      gdpAdjusted: 28779,
      changeAdjusted: 3.75,
    },
    SL: { gdp: 492, change: -5.88, gdpAdjusted: 1553, changeAdjusted: 1.35 },
    SG: {
      gdp: 57713,
      change: 4.48,
      gdpAdjusted: 93906,
      changeAdjusted: 2.22,
    },
    SK: {
      gdp: 17664,
      change: 6.73,
      gdpAdjusted: 33025,
      changeAdjusted: 3.61,
    },
    SI: {
      gdp: 23654,
      change: 9.17,
      gdpAdjusted: 34408,
      changeAdjusted: 4.93,
    },
    SB: { gdp: 2081, change: 1.18, gdpAdjusted: 2157, changeAdjusted: 1.29 },
    SO: { gdp: 486, change: 6.38, gdpAdjusted: 1229, changeAdjusted: -1.82 },
    ZA: {
      gdp: 6180,
      change: 16.25,
      gdpAdjusted: 13545,
      changeAdjusted: -0.07,
    },
    KR: {
      gdp: 29891,
      change: 8.56,
      gdpAdjusted: 39434,
      changeAdjusted: 2.72,
    },
    SS: {
      gdp: 228,
      change: -18.73,
      gdpAdjusted: 1489,
      changeAdjusted: -12.82,
    },
    ES: {
      gdp: 28359,
      change: 6.31,
      gdpAdjusted: 38286,
      changeAdjusted: 3.01,
    },
    LK: { gdp: 4085, change: 6.95, gdpAdjusted: 12811, changeAdjusted: 3.46 },
    SD: { gdp: 1428, change: -1.91, gdpAdjusted: 4586, changeAdjusted: 0.82 },
    SR: {
      gdp: 5746,
      change: 0.76,
      gdpAdjusted: 13876,
      changeAdjusted: -0.87,
    },
    SZ: {
      gdp: 3915,
      change: 15.19,
      gdpAdjusted: 9884,
      changeAdjusted: -0.67,
    },
    SE: {
      gdp: 53218,
      change: 3.39,
      gdpAdjusted: 51475,
      changeAdjusted: 1.68,
    },
    CH: {
      gdp: 80591,
      change: 0.35,
      gdpAdjusted: 61422,
      changeAdjusted: 0.24,
    },
    TW: {
      gdp: 24577,
      change: 9.03,
      gdpAdjusted: 50294,
      changeAdjusted: 2.51,
    },
    TJ: { gdp: 824, change: 2.54, gdpAdjusted: 3211, changeAdjusted: 5.06 },
    TZ: { gdp: 1034, change: 5.58, gdpAdjusted: 3240, changeAdjusted: 2.96 },
    TH: {
      gdp: 6591,
      change: 10.39,
      gdpAdjusted: 17855,
      changeAdjusted: 3.68,
    },
    TL: { gdp: 2104, change: 1.19, gdpAdjusted: 5444, changeAdjusted: -2.67 },
    TG: { gdp: 611, change: 4.24, gdpAdjusted: 1659, changeAdjusted: 1.95 },
    TO: { gdp: 4177, change: 5.56, gdpAdjusted: 5608, changeAdjusted: 2.26 },
    TT: {
      gdp: 15769,
      change: -3.46,
      gdpAdjusted: 31367,
      changeAdjusted: -2.58,
    },
    TN: {
      gdp: 3496,
      change: -5.24,
      gdpAdjusted: 11755,
      changeAdjusted: 0.85,
    },
    TR: {
      gdp: 10512,
      change: -2.82,
      gdpAdjusted: 26893,
      changeAdjusted: 5.68,
    },
    TM: { gdp: 6643, change: 3.59, gdpAdjusted: 18126, changeAdjusted: 4.86 },
    TV: { gdp: 3638, change: 2.24, gdpAdjusted: 3807, changeAdjusted: 2.34 },
    UG: { gdp: 699, change: 1.04, gdpAdjusted: 2354, changeAdjusted: 2.86 },
    UA: { gdp: 2583, change: 17.46, gdpAdjusted: 8713, changeAdjusted: 3.02 },
    AE: {
      gdp: 37226,
      change: 5.21,
      gdpAdjusted: 67741,
      changeAdjusted: -0.86,
    },
    GB: {
      gdp: 39735,
      change: -1.96,
      gdpAdjusted: 44117,
      changeAdjusted: 1.21,
    },
    US: {
      gdp: 59501,
      change: 3.37,
      gdpAdjusted: 59501,
      changeAdjusted: 1.48,
    },
    UY: {
      gdp: 16722,
      change: 11.02,
      gdpAdjusted: 22371,
      changeAdjusted: 2.74,
    },
    UZ: {
      gdp: 1491,
      change: -28.81,
      gdpAdjusted: 6929,
      changeAdjusted: 3.89,
    },
    VU: { gdp: 3094, change: 6.51, gdpAdjusted: 2739, changeAdjusted: 2.33 },
    VE: {
      gdp: 6684,
      change: -12.16,
      gdpAdjusted: 12114,
      changeAdjusted: -15.3,
    },
    VN: { gdp: 2354, change: 8.37, gdpAdjusted: 6913, changeAdjusted: 5.78 },
    YE: {
      gdp: 551,
      change: -23.23,
      gdpAdjusted: 1287,
      changeAdjusted: -16.17,
    },
    ZM: { gdp: 1480, change: 18.11, gdpAdjusted: 3996, changeAdjusted: 0.95 },
    ZW: { gdp: 1176, change: 5.78, gdpAdjusted: 2283, changeAdjusted: 1.02 },
  },
};
